import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch("https://admin.pankajporwal.live/api/projects")
      .then((response) => response.json())
      .then((data) => {
        setProjects(data.projects); // Store fetched projects in state
      })
      .catch((error) => console.error("Error fetching projects:", error));
  }, []);

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <h3 style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </h3>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          {projects.map((project, index) => (
            <Col md={4} className="project-card" key={index}>
              <ProjectCard
                imgPath={project.image}
                showButtons={true}
                title={project.name}
                description={project.description}
                ghLink={project.github}
                demoLink={project.link}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

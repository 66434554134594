import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);
  const [resumeUrl, setResumeUrl] = useState(null);

  useEffect(() => {
    setWidth(window.innerWidth);

    // Fetch resume URL from backend
    fetch("https://admin.pankajporwal.live/api/resume")
      .then((res) => res.json())
      .then((data) => {
        if (data.resume_url) {
          setResumeUrl(data.resume_url);
        }
      })
      .catch((err) => console.error("Error fetching resume:", err));
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>
          {resumeUrl && (
            <Button variant="primary" href={resumeUrl} target="_blank" style={{ maxWidth: "250px" }}>
              <AiOutlineDownload />
              &nbsp;Download CV
            </Button>
          )}
        </Row>

        <Row className="resume">
          {resumeUrl && (
            <Document file={resumeUrl} className="d-flex justify-content-center">
              <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
            </Document>
          )}
        </Row>

        <Row style={{ justifyContent: "center", position: "relative" }}>
          {resumeUrl && (
            <Button variant="primary" href={resumeUrl} target="_blank" style={{ maxWidth: "250px" }}>
              <AiOutlineDownload />
              &nbsp;Download CV
            </Button>
          )}
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
